<template>
  <div>
    <div class="font-weight-bold mb-2">{{ $t('label.photos') }}</div>
    <silent-box v-if="images && images.length" :gallery="gallery"></silent-box>
    <span v-else class="btn btn-sm btn-text btn-light-dark font-weight-bold w-100">
      {{ $t('label.no_photos_found') }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    images: {
      default: () => []
    },
    itemHeight: {
      default: '50px'
    },
    itemWidth: {
      default: '50px'
    }
  },
  data: () => ({
    gallery: []
  }),
  watch: {
    images: {
      immediate: true,
      handler(data) {
        this.gallery = data.map(el => {
          return {
            src: el.path,
            thumbnailHeight: this.itemHeight,
            thumbnailWidth: this.itemWidth
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#silentbox-gallery::v-deep {
  .silentbox-item {
    margin: 0 2px;
  }
}
</style>