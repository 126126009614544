<template>
  <div class="timeline timeline-6 mt-3">
    <!--begin::Item-->
    <div v-for="item in history" class="timeline-item align-items-center">
      <!--begin::Label-->
      <div class="d-flex flex-column align-items-center timeline-label">
        <div class="font-weight-bolder text-dark-75 font-size-sm">{{ item.created_date }}</div>
        <div class="font-weight-bolder text-dark-75 font-size-sm">{{ item.created_time }}</div>
      </div>
      <!--end::Label-->

      <!--begin::Badge-->
      <div class="timeline-badge">
        <i class="fa fa-genderless icon-xl" :class="`text-${getBadgeClass(item)}`"></i>
      </div>
      <!--end::Badge-->
      <div v-if="item.key !== 'created_at' && item.key !== 'deleted_at'" class="d-flex flex-column pl-3">
        <!--begin::Text-->
        <div class="font-weight-normal font-size-lg timeline-content text-muted">{{ $t(`label.${item.key}`) }}</div>
        <div class="font-weight-normal font-size-lg timeline-content">
          <span :class="item.key === 'status_id' ? `text-${getTicketStatusClass(item.old_value)}` : ''">
            {{ item.key === 'status_id' ? $t(`status.${item.old_value}`) : item.old_value }}
          </span>
          <span class="mx-2">→</span>
          <span :class="item.key === 'status_id' ? `text-${getTicketStatusClass(item.new_value)}` : ''">
            {{ item.key === 'status_id' ? $t(`status.${item.new_value}`) : item.new_value }}
          </span>
        </div>
        <div class="font-weight-normal font-size-lg timeline-content text-muted">{{ item.user_id.label }}</div>
      </div>
      <div v-else class="font-size-lg font-weight-boldest pl-3">{{ $t(`label.${entity}_${item.key}`) }} / {{ item.user_id.label }}</div>
      <!--end::Text-->
    </div>
    <!--end::Item-->
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {getTicketStatusClass} from '@/helpers/statuses-helper';

export default {
  props: {
    entity: {
      type: String,
      default: ''
    },
    requestUrl: {
      type: String,
      default: ''
    }
  },
  beforeMount() {
    this.$store.dispatch('historyStore/GET', this.requestUrl);
  },
  computed: {
    ...mapGetters({
      history: 'historyStore/HISTORY'
    })
  },
  methods: {
    getBadgeClass(item) {
      let className = 'warning';

      if (item.key === 'created_at') {
        className = 'success';
      } else if (item.key === 'deleted_at') {
        className = 'danger';
      }

      return className;
    },
    getTicketStatusClass
  },
}
</script>