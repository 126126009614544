<template>
  <div>
    <div v-if="ticket && statuses" class="d-flex ticket-statuses-wrapper my-7">
      <div v-for="status in statuses"
           class="ticket-status text-center text-white cursor-pointer w-100 p-3"
           :class="[
               ticket.status.id === status.id ? `bg-${getTicketStatusClass(status.name)}` : `bg-${getTicketStatusClass(status.name)}-o-100`,
               `bg-hover-${getTicketStatusClass(status.name)}`
           ]"
           @click="changeStatus(status.id)"
      >
        {{ $t(`status.${status.name}`) }}
      </div>
    </div>

    <app-card v-if="ticket" card-class="h-auto">
      <template #body>
        <div class="d-flex">
          <!--begin: Info-->
          <div class="flex-grow-1">
            <!--begin: Title-->
            <div class="d-flex align-items-center justify-content-between flex-wrap">
              <div class="mr-3">
                <!--begin::Name-->
                <div class="d-flex align-items-center text-dark font-size-h5 font-weight-bold mr-3">
                  {{ ticket.title }}
                </div>
                <!--end::Name-->
              </div>
            </div>
            <!--end: Title-->
            <!--begin: Content-->
            <b-row class="align-items-center">
              <b-col md="6">
                <div class="font-weight-bold text-dark-50 py-5 py-lg-2 mr-5">
                  {{ ticket.description }}
                </div>
              </b-col>
              <b-col md="6">
                <b-row>
                  <b-col md="3" class="mb-4">
                    <div class="font-weight-bold mb-2">{{ $t('label.responsible') }}</div>
                    <div class="font-size-h5 font-weight-boldest w-100 mt-3">
                      {{ ticket.responsible.label }}
                    </div>
                  </b-col>
                  <b-col md="3" class="mb-4">
                    <div class="font-weight-bold mb-2">{{ $t('label.created_at') }}</div>
                    <div class="font-size-h5 font-weight-boldest w-100 mt-3">
                      {{ ticket.created_at }}
                    </div>
                  </b-col>
                  <b-col md="3" class="mb-4">
                    <div class="font-weight-bold mb-2">{{ $t('label.deadline') }}</div>
                    <div class="text-danger font-size-h5 font-weight-boldest w-100 mt-3">
                      {{ ticket.deadline }}
                    </div>
                  </b-col>
                  <b-col md="3" class="mb-4">
                    <app-images-gallery :images="images"/>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <!--end: Content-->
          </div>
          <!--end: Info-->
        </div>
        <div class="separator separator-solid my-7"></div>
        <!--begin: Items-->
        <b-row>
          <b-col cols="6" md="2">
            <div class="d-flex align-items-center flex-lg-fill my-1">
              <span class="mr-4">
                <inline-svg src="/media/svg/icons/General/User.svg"/>
              </span>
              <div class="d-flex flex-column text-dark-75">
                <span class="font-weight-bolder font-size-sm">{{ $t('label.created_by') }}</span>
                <span class="font-weight-bolder font-size-h5">{{ ticket.created_by.label }}</span>
              </div>
            </div>
          </b-col>
          <b-col cols="6" md="2">
            <div class="d-flex align-items-center flex-lg-fill my-1">
            <span class="mr-4">
              <inline-svg src="/media/svg/icons/Home/Building.svg"/>
            </span>
              <div class="d-flex flex-column text-dark-75">
                <span class="font-weight-bolder font-size-sm">{{ $t('label.complex') }}</span>
                <router-link :to="{name: 'updateComplex', params: {id: ticket.location.block.complex.id}}">
                  <span class="font-weight-bolder font-size-h5">{{ ticket.location.block.complex.name }}</span>
                </router-link>
              </div>
            </div>
          </b-col>
          <b-col cols="6" md="2">
            <div class="d-flex align-items-center flex-lg-fill my-1">
            <span class="mr-4">
              <inline-svg src="/media/svg/icons/Layout/Layout-4-blocks.svg"/>
            </span>
              <div class="d-flex flex-column text-dark-75">
                <span class="font-weight-bolder font-size-sm">{{ $t('label.block') }}</span>
                <router-link :to="{name: 'showBlock', params: {id: ticket.location.block.id}}">
                  <span class="font-weight-bolder font-size-h5">{{ ticket.location.block.name }}</span>
                </router-link>
              </div>
            </div>
          </b-col>
          <b-col cols="6" md="2">
            <div class="d-flex align-items-center flex-lg-fill my-1">
            <span class="mr-4">
              <inline-svg src="/media/svg/icons/Layout/Layout-3d.svg"/>
            </span>
              <div class="d-flex flex-column text-dark-75">
                <span class="font-weight-bolder font-size-sm">{{ $t('label.location_type') }}</span>
                <span class="font-weight-bolder font-size-h5">{{ ticket.location_type.label }}</span>
              </div>
            </div>
          </b-col>
          <b-col cols="6" md="2">
            <div class="d-flex align-items-center flex-lg-fill my-1">
            <span class="mr-4">
             <inline-svg src="/media/svg/icons/Home/Home.svg"/>
            </span>
              <div class="d-flex flex-column text-dark-75">
                <span class="font-weight-bolder font-size-sm">{{ $t('label.location') }}</span>
                <span class="font-weight-bolder font-size-h5">{{ ticket.location.name }}</span>
              </div>
            </div>
          </b-col>
          <b-col cols="6" md="2">
            <div class="d-flex align-items-center flex-lg-fill my-1">
            <span class="mr-4">
             <inline-svg src="/media/svg/icons/Files/File.svg"/>
            </span>
              <div class="d-flex flex-column text-dark-75">
                <span class="font-weight-bolder font-size-sm">{{ $t('label.contract_status') }}</span>
                <span class="font-weight-bolder font-size-h5">Lorem</span>
              </div>
            </div>
          </b-col>
        </b-row>
        <div class="d-flex align-items-center flex-wrap">
          <!--begin: Item-->
          <!--end: Item-->
          <!--begin: Item-->

          <!--end: Item-->
          <!--begin: Item-->
          <!--end: Item-->
          <!--begin: Item-->
          <!--end: Item-->
          <!--begin: Item-->
          <!--end: Item-->
          <!--begin: Item-->
          <!--end: Item-->
        </div>
      </template>
    </app-card>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {getTicketStatusClass} from '@/helpers/statuses-helper';
import AppCard from '@/components/AppCard';
import AppImagesGallery from '@/components/images/AppImagesGallery';

export default {
  components: {
    AppCard,
    AppImagesGallery
  },
  props: {
    id: {
      default: null
    }
  },
  beforeMount() {
    this.$store.dispatch('ticketsStore/GET_TICKET', this.id);
    this.$store.dispatch('ticketsStore/GET_STATUSES');
    this.$store.dispatch('imagesStore/GET', {entity: 'ticket', entityId: this.id});
  },
  computed: {
    ...mapGetters({
      images: 'imagesStore/IMAGES',
      ticket: 'ticketsStore/TICKET',
      statuses: 'ticketsStore/STATUSES',
    })
  },
  methods: {
    getTicketStatusClass,
    changeStatus(id) {
      this.$store.dispatch('ticketsStore/UPDATE_STATUS', {ticketId: this.id, statusId: id}).then(() => {
        this.$store.dispatch('ticketsStore/GET_TICKET', this.id);
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.ticket-statuses-wrapper::v-deep {
  .ticket-status:first-child {
    border-top-left-radius: 0.42rem;
    border-bottom-left-radius: 0.42rem;
  }

  .ticket-status:last-child {
    border-top-right-radius: 0.42rem;
    border-bottom-right-radius: 0.42rem;
  }
}
</style>