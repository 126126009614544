<template>
  <div>
    <ticket-card :id="ticketId"/>

    <b-row>
      <b-col md="4" class="mt-7">
        <comments-list class="h-350px" entity="ticket" :entity-id="ticketId"/>
      </b-col>

      <b-col md="4" class="mt-7">
        <app-card>
          <template #title>{{ $t('label.tasks') }}</template>
          <template #toolbar>
            <router-link :to="{name: 'createTask', params: {ticketId: ticketId}}">
              <a :href="`/tickets/${ticketId}/tasks/0/create`" class="btn btn-light-info">
                {{ $t('btn.create_task') }}
              </a>
            </router-link>
          </template>
          <template #body>
            <app-table
                :request-url="`/api/tasks/table?filters=${JSON.stringify(taskFilters)}`"
                :fields="fields"
                hide-pagination
                :row-class="taskRowClass"
            >
              <template #cell(title)="data">
                <router-link :to="{name: 'showTask', params: {id: data.item.id, ticketId: ticketId}}">
                  {{ data.value }}
                </router-link>
              </template>
              <template #cell(status)="data">
                <span class="label label-pill label-inline"
                      :class="`label-light-${getTaskStatusClass(data.value.name)}`">
                  {{ $t(`status.${data.value.name}`) }}
                </span>
              </template>
              <template #cell(responsible)="data">
                <router-link :to="{name: 'updateUser', params: {id: data.value.id}}">
                  {{ data.value.full_name }}
                </router-link>
              </template>
            </app-table>
          </template>
        </app-card>
      </b-col>

      <b-col md="4" class="mt-7">
        <app-card body-class="max-h-500px overflow-auto">
          <template #title>{{ $t('label.history') }}</template>
          <template #body>
            <app-history
                entity="ticket"
                :request-url="`api/tickets/${ticketId}/history`"
            />
          </template>
        </app-card>
      </b-col>
    </b-row>

    <portal to="subheader-toolbar">
      <b-button class="mr-3" variant="transparent-white" @click="$router.push({name: 'ticketsList'})">
        {{ $t('btn.back_to_list') }}
      </b-button>
      <b-button class="mr-3" variant="danger" v-b-modal.delete-ticket-modal>
        {{ $t('btn.delete') }}
      </b-button>
      <router-link :to="{name: 'updateTicket', params: {id: ticketId}}" class="btn btn-light-success">
        {{ $t('btn.update') }}
      </router-link>
    </portal>

    <app-modal
        id="delete-ticket-modal"
        :title="$t('label.confirm_action')"
        :ok-text="$t('btn.delete')"
        ok-variant="danger"
        @ok="deleteItem"
    >
      <template #app-modal-body>
        <span class="font-size-lg">{{ $t('modal.delete_ticket') }}?</span>
      </template>
    </app-modal>
  </div>
</template>

<script>
import {getTaskStatusClass} from '@/helpers/statuses-helper';
import AppCard from '@/components/AppCard';
import AppHistory from '@/components/history/AppHistory';
import AppModal from '@/components/modals/AppModal';
import AppTable from '@/components/lists/AppTable';
import CommentsList from '@/components/comments/CommentsList';
import TicketCard from './components/TicketCard';

export default {
  components: {
    AppCard,
    AppHistory,
    AppModal,
    AppTable,
    CommentsList,
    TicketCard
  },
  data() {
    return {
      ticketId: null,
      taskFilters: null,
      fields: [
        {key: 'title', label: this.$t('label.title')},
        {key: 'status', label: this.$t('label.status'), sortable: true},
        {key: 'responsible', label: this.$t('label.responsible')}
      ]
    }
  },
  beforeMount() {
    this.ticketId = this.$route.params.id;
    this.taskFilters = {ticket: this.ticketId};
  },
  methods: {
    getTaskStatusClass,
    goToUpdate() {
      this.$router.push({name: 'updateTicket', params: {id: this.ticketId}});
    },
    goToCreateTask() {
      this.$router.push({name: 'createTask', params: {ticketId: this.ticketId}});
    },
    deleteItem() {
      this.$store.dispatch('ticketsStore/DELETE', this.ticketId).then(() => {
        this.$router.push({name: 'ticketsList'});
      });
    },
    taskRowClass(item, type) {
      if (!item || type !== 'row') return '';
      if (item.deadline_warning && item.status.name !== 'completed') return 'bg-warning-o-30';
    }
  }
}
</script>