<template>
  <app-card body-class="overflow-hidden p-0">
    <template #title>{{ $t('label.comments') }}</template>
    <template #toolbar>
      <b-button variant="light-info" @click="goToDiscussion">{{ $t('btn.go_to_discussion') }}</b-button>
    </template>
    <template #body>
      <div v-if="comments.length" class="comments-wrapper max-h-500px overflow-auto px-7 pt-7" :class="{'with-quote': form.parent_id}">
        <div v-for="(comment) in comments" class="comment-wrapper my-2">
          <!--begin::Header-->
          <div class="d-flex align-items-center pb-1">
            <!--begin::Info-->
            <div class="d-flex flex-column flex-grow-1 mb-3">
              <div class="d-flex text-dark-75 justify-content-between mb-1 font-size-lg">
                <span class="font-weight-bolder">{{ comment.creator.label }}</span>
                <span class="reply-text text-muted text-hover-primary cursor-pointer"
                      @click="form.parent_id = comment.id">{{ $t('label.reply') }}</span>
              </div>
              <span class="text-muted font-weight-bold font-size-sm">{{ comment.created_at }}</span>
            </div>
            <!--end::Info-->
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div>
            <div v-if="comment.parent" class="parent-comment text-dark-50 mb-2 pl-2">{{ comment.parent.text }}</div>
            <!--begin::Text-->
            <p class="text-dark-75 font-size-lg font-weight-normal">
              {{ comment.text }}
            </p>
            <!--end::Text-->
          </div>
          <!--end::Body-->
        </div>
      </div>
      <div v-else class="d-flex justify-content-center align-items-center h-100 text-muted font-size-h5 min-h-200px">
        {{ $t('label.no_comments_yet') }}
      </div>
      <!--end::Container-->
      <div class="comments-reply-wrapper position-absolute w-100 bg-white px-3">
        <div v-if="form.parent_id" class="d-flex justify-content-between parent-comment pt-5 pb-3 pl-2">
          <span>{{ computedParentText }}</span>
          <inline-svg
              src="/media/svg/icons/Navigation/Close.svg"
              class="cursor-pointer"
              @click="form.parent_id = null"
          />
        </div>
        <div class="separator separator-solid mt-2 mb-4"></div>
        <div class="d-flex justify-content-between align-items-center">
          <textarea v-model="form.text"
                    class="form-control border-0 p-0 pr-10 resize-none"
                    rows="1"
                    :placeholder="form.parent_id ? $t('placeholder.reply') : $t('placeholder.write_comment')"
          />
          <b-button size="sm" variant="primary" :disabled="!form.text" @click="submit">{{ $t('btn.send') }}</b-button>
        </div>
      </div>
    </template>
  </app-card>
</template>

<script>
import {mapGetters} from 'vuex';
import AppCard from '@/components/AppCard';

export default {
  components: {
    AppCard
  },
  props: {
    entity: {
      type: String,
      default: null
    },
    entityId: {
      default: null
    }
  },
  data: () => ({
    form: {
      parent_id: null,
      text: ''
    }
  }),
  beforeMount() {
    this.getData();
  },
  computed: {
    ...mapGetters({
      comments: 'commentsStore/COMMENTS'
    }),
    computedParentText() {
      return this.comments.find(el => el.id === this.form.parent_id).text;
    }
  },
  methods: {
    goToDiscussion() {
      this.$router.push({name: `${this.entity}Discussion`, params: {id: this.entityId}});
    },
    getData() {
      this.$store.dispatch('commentsStore/GET', {entity: this.entity, entityId: this.entityId});
    },
    async submit() {
      await this.$store.dispatch('commentsStore/CREATE', {
        entity: this.entity,
        entityId: this.entityId,
        dataToSubmit: this.form
      });
      this.clearForm();
      this.getData();
    },
    clearForm() {
      this.form.parent_id = null;
      this.form.text = '';
    },
  },
  beforeDestroy() {
    this.$store.commit('commentsStore/CLEAR_COMMENTS');
  }
}
</script>

<style lang="scss" scoped>
.comments-wrapper {
  height: calc(100% - 75px);

  &.with-quote {
    height: calc(100% - 115px);
  }

  .comment-wrapper {
    &:hover {
      .reply-text {
        display: block;
      }
    }

    .reply-text {
      display: none;
    }
  }
}

.parent-comment {
  border-left: 5px solid #E4E6EF;
}

.comments-reply-wrapper {
  bottom: 10px;
}
</style>